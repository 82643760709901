/**
 * tools/_font-size.scss
 */

/* ==========================================================================
   Font size
   ========================================================================== */

/**
 * Font sizing mixin - calculates font-size in rems with px fallback and
 * line-height ratio based on default baseline height
 */

@mixin font-size($font-size, $line-height: auto) {
  font-size: $font-size;
  font-size: ($font-size / $base-font-size) * 1rem;

  @if $line-height == auto {
    line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
  }

  @else {
    @if (type-of($line-height) == number or $line-height == inherit or $line-height == normal) {
      line-height: $line-height;
    }

    @elseif ($line-height == none or $line-height == false) {
      line-height: inherit;
    }

    @else {
      @warn "‘#{$line-height}’ is not a valid value for `line-height`.";
    }
  }
}
