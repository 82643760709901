/**
 * objects/_list-inline.scss
 */

/* ==========================================================================
   Inline lists
   ========================================================================== */

/**
 * Throw lists into inline mode
 */

%list--inline,
.list--inline {
  list-style: none;
  padding-left: 0;

  > li {
    display: inline-block;
  }
}
