/**
 * components/_flash-message.scss
 */

/* ==========================================================================
   Flash messages
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$neutral__color:            color(bw, white);
$neutral__background-color: color(background, darkest);
$info__background-color:    color(context, info);
$success__background-color: color(context, success);
$warning__background-color: color(context, warning);
$danger__background-color:  color(context, danger);


/**
 * Base flash message component
 */

.flash-message {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1;
  padding: halve($base-spacing-unit);
  overflow: hidden;
  color: $neutral__color;
  background-color: $neutral__background-color;

  @include media-query(palm) {
    bottom: 0;
    background-color: transparentize($neutral__background-color, 0.15);
  }

  @include media-query(desk) {
    padding: $base-spacing-unit;
    position: relative;
  }

  &__container {
    display: flex;
    align-items: center;

    @include media-query(portable) {
      flex-direction: column;
    }
  }

  &__controls {
    @include media-query(palm) {
      display: flex;
      width: 100%;

      > * {
        flex: 1 0 50%;
        text-align: center;
      }
    }
  }

  &__btn {
    margin: 1em 0;

    @include media-query(palm) {
      margin: 0;
      border-radius: 0;
    }

    @include media-query(lap) {
      align-self: flex-start;
    }

    @include media-query(desk) {
      margin: 0 1em;
    }
  }

  &__close {
    color: #fff;

    @include media-query(palm) {
      background: color(bw, black);

      .icon {
        display: none;
      }
    }

    @include media-query(lap-and-up) {
      position: absolute;
      top: 0;
      right: 0;
      width: 44px;
      height: 44px;

      &:hover .icon {
        fill: shade(#898989, 66.666%);
      }
    }
  }
}


/**
 * Context modifiers
 */

.flash-message {
  &--info {
    background-color: $info__background-color;
  }

  &--warning {
    background-color: $warning__background-color;
  }

  &--danger {
    background-color: $danger__background-color;
  }

  &--confirmed {
    background-color: $success__background-color;
  }
}
