/**
 * components/_input.scss
 */

/* ==========================================================================
   Input
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$background-color: #f4f4f4;
$border-color:     #e9e9e9;


/**
 * Input styles
 */

.input {
  display: block;
  padding: quarter($base-spacing-unit);
  width: 100%;
  background-color: $background-color;
  border: 2px solid $border-color;
}


/**
 * File input styles
 */

.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  height: 41px;

  &__input {
    min-width: 14rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
  }

  &__custom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    padding: quarter($base-spacing-unit);
    width: 100%;
    height: 41px;
    line-height: 1.5;
    background-color: $background-color;
    border: 2px solid $border-color;
    user-select: none;

    &::before {
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      z-index: 6;
      display: block;
      content: "Browse";
      height: 41px;
      padding: quarter($base-spacing-unit) $base-spacing-unit;
      line-height: 26px;
      background-color: $border-color;
      border: 2px solid $border-color;
    }

    &::after {
      content: "Choose file...";
      line-height: 26px;
    }
  }
}
