/**
 * base/_table.scss
 */

/* ==========================================================================
   Tables
   ========================================================================== */

/**
 * Table base styles
 */

 table {
   border-collapse: collapse;
   border-spacing: 0;
   tr {

   }
   th {

   }
   td {
     padding: 0.5em 1em;
     border: 1px solid #CCC;
   }
 }
