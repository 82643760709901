/**
 * base/_lists.scss
 */

/* ==========================================================================
   Lists
   ========================================================================== */

/**
 * Style base lists
 */

ul,
ol {
  padding-left: $base-spacing-unit;
  list-style-type: circle;
}
