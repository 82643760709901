/**
 * components/_nav-toggle.scss
 */

/* ==========================================================================
   Navigation Menu Toggle
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$menu-icon-color: color(bw, white);


/**
 * Navigation Toggle button
 */

.nav-toggle {
  @include media-query(portable) {
    display: block;
    padding: ((50px / 2) - 2) ((50px / 2) - 10);
    width: 18px;
    height: 2px;
    border: 0;
    box-sizing: content-box;
    background-clip: content-box;
    transition: background-color 500ms ease-in-out 250ms;
    background-color: $menu-icon-color;

    &::before,
    &::after {
      position: relative;
      content: '';
      display: block;
      width: 18px;
      height: 2px;
      background-color: $menu-icon-color;
      transition: transform 500ms ease-in-out;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -4px;
    }

    &__text {
      display: none;
    }

    &--active {
      background-color: rgba(255, 255, 255, 0);
      transition-delay: 0s;

      &::before {
        transform: translateY(6px) rotate(-225deg);
      }

      &::after {
        transform: translateY(-6px) rotate(225deg);
      }
    }
  }
}
