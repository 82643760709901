/**
 * components/_select.scss
 */

/* ==========================================================================
   Select
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$background-color: #f4f4f4;
$border-color:     #e9e9e9;


/**
 * Select styles
 */

.select {
  position: relative;

  select {
    display: block;
    padding: quarter($base-spacing-unit);
    width: 100%;
    appearance: none;
    background-color: $background-color;
    border-radius: 0;
    border: 2px solid $border-color;
  }

  &::after {
    position: absolute;
    top: 20px;
    right: halve($base-spacing-unit);
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: quarter($base-spacing-unit) solid transparent;
    border-right: quarter($base-spacing-unit) solid transparent;
    border-top: quarter($base-spacing-unit) solid color(text, medium);
    transform: translateY(-50%);
    pointer-events: none;
  }
}
