/**
 * objects/_wrapper.scss
 */

/* ==========================================================================
   Wrapper
   ========================================================================== */

/**
 * Our wrapper object wraps all the main content on the page.
 */

.wrapper {
  @include media-query(desk) {
    padding: quarter($base-spacing-unit);
  }
}
