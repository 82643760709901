/**
 * components/_controls.scss
 */

/* ==========================================================================
   Controls
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// $example;


/**
 * Control
 */

.control {
  text-decoration: none;
  color: color(text, medium);

  @include media-query(portable) {
    display: block;
    width: double($base-spacing-unit);
    height: double($base-spacing-unit);
    background-color: rgba(255, 255, 255, 0.1);
  }

  @include media-query(desk) {
    display: flex;
    align-items: center;
  }

  &__text {
    @include media-query(portable) {
      display: none;
    }
  }

  &__icon-wrapper {
    position: relative;
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.05);
    transition: background-color $base-anim-duration $base-anim-easing;

    @include media-query(desk) {
      margin-left: halve($base-spacing-unit);
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--account {
    &:hover .control__icon-wrapper {
      @include media-query(desk) {
        background-color: #c0392b;
      }
    }
  }

  &--menu-toggle {
    display: block;

    @include media-query(desk) {
      display: none;
    }
  }
}
