/**
 * components/_video.scss
 */

/* ==========================================================================
   Video
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$video__trigger--color: color(bw, white);


/**
 * Video
 */

.video-widget {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    content: '';
    background: rgba(0, 0, 0, 0.25);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.8) 100%);
  }

  &__trigger {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    display: block;
    width: 96px;
    height: 96px;
    border: 2px solid $video__trigger--color;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: opacity $base-anim-duration $base-anim-easing;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 34px;
      fill: $video__trigger--color;
      transform: translate(-50%, -50%);
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &__title {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    padding: $base-spacing-unit;
    text-align: right;
    color: color(bw, white);
  }
}

/**
 * Embedded video
 */

.video-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
