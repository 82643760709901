/**
 * components/_table.scss
 */

/* ==========================================================================
   Table
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// $example;


/**
 * Tables
 */

.table {
  margin: $base-spacing-unit 0;
  width: 100%;
  overflow-x: auto;

  > table {
    width: 100%;
    border-collapse: collapse;
  }


  &__head {
    color: color(text, lightest);
    background-color: color(brand, primary);

    .table__cell {
      text-align: left;
      font-weight: 400;
    }
  }

  &__body {

    > .table__row {
      &:nth-child(odd) {
        background-color: #fff;
      }

      &:nth-child(even) {
        background-color: #f4f4f4;
      }
    }

    .table__cell {
      border: 1px solid #e9e9e9;
    }
  }

  &__cell {
    padding: 0.5em 1em;
    white-space: nowrap;
  }
}
