/**
 * base/_rules.scss
 */

/* ==========================================================================
   Horizontal rules
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$hr__color: color(background, light);


/**
 * Horizontal rules styles
 */

hr {
  margin: 3em 0;
  border: 0;
  border-top: 1px solid $hr__color;
}
