/**
 * base/legend.scss
 */

/* ==========================================================================
   Legend
   ========================================================================== */

/**
 * Base legend styles
 */

legend {
  @include font-size(24px);
  margin: 0 0 1.5em;
  font-weight: bold;
  color: color(brand, primary);
}
