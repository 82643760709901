/**
 * components/_field.scss
 */

/* ==========================================================================
   Field
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// $example;


/**
 * Field
 */

.field {
  margin-bottom: $base-spacing-unit;
}
