/**
 * objects/_page.scss
 */

/* ==========================================================================
   Page
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$header__height--portable: 50px;


/**
 * Page layout
 */

.page {
  @include media-query(desk) {
    display: flex;
    flex-direction: column;
    min-height: 98vh;

    .no-flexboxlegacy & {
      display: block;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include media-query(portable) {
      margin-top: 50px;
    }

    @include media-query(desk) {
      flex: 1;
      flex-direction: row;
      margin-bottom: quarter($base-spacing-unit);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;

    @include media-query(desk) {
      flex: 0 0 20em;
      margin-right: quarter($base-spacing-unit);
    }
  }

  &__nav {
    flex: 1;

    @include media-query(portable) {
      position: fixed;
      top: $header__height--portable;
      left: -100%;
      bottom: 0;
      z-index: 1;
      width: 100%;
      max-width: 320px;
      transition: left $base-anim-duration $base-anim-easing;

      &--open {
        left: 0;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
