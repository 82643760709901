/**
 * objects/_list-bare.scss
 */

/* ==========================================================================
   List inline
   ========================================================================== */

/**
 * Remove natural styling of lists
 */

%list--bare,
.list--bare {
  list-style: none;
  padding-left: 0;
}
