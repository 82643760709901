/**
 * components/_callout.scss
 */

/* ==========================================================================
   Callouts
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$callout__border-width: 1px;
$callout__border-color: color(bw, black);

$callout--info: color(context, info);
$callout--warning: color(context, warning);
$callout--danger: color(context, danger);
$callout--success: color(context, success);


/**
 * Base callout styles
 */

.callout {
  margin-bottom: $base-spacing-unit;
  padding: halve($base-spacing-unit);
  border: $callout__border-width solid $callout__border-color;
  visibility: visible;
  opacity: 1;
  transition:
    visibility 0s linear,
    opacity 0.5s linear;

  &__heading {
    margin: 0;
  }

  p {
    margin: 0;
  }
}



/**
 * Callout state modifiers
 */

.callout--info {
  border-color: $callout--info;
  background-color: tint($callout--info, 40%);
}

.callout--warning {
  border-color: $callout--warning;
  background-color: tint($callout--warning, 40%);
}

.callout--danger {
  border-color: $callout--danger;
  background-color: tint($callout--danger, 40%);
}

.callout--success {
  border-color: $callout--success;
  background-color: tint($callout--success, 40%);
}

.callout--hidden {
  margin: 0;
  padding: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition-delay: 0s;
}
