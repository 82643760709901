/**
 * base/_adjacent.scss
 */

/* ==========================================================================
   Adjacent
   ========================================================================== */

/**
 * Remove bottom margin
 */

.adjacent {
  margin-bottom: 0;
}
