/**
 * components/_textarea.scss
 */

/* ==========================================================================
   Textarea
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$background-color: #f4f4f4;
$border-color: #e9e9e9;


/**
 * Textarea styles
 */

.textarea {
  display: block;
  padding: quarter($base-spacing-unit);
  width: 100%;
  height: 130px;
  background-color: $background-color;
  border: 2px solid $border-color;
}
