/**
 * components/_categories.scss
 */

/* ==========================================================================
   Categories
   ========================================================================== */

/**
 * Categories links
 */

.categories {
  display: inline;
  padding: 0;
  list-style: none;

  &__category {
    display: inline;

    &:not(:last-child):not(:nth-last-child(2)) {
      &::after {
        content: ", ";
      }
    }

    &:nth-last-child(2) {
      &::after {
        content: " & ";
      }
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
