/**
 * objects/_content.scss
 */

/* ==========================================================================
   Content
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// $example;


/**
 * Main content area
 */

.content {
  &__body {
    padding: halve($base-spacing-unit);

    @include media-query(lap-and-up) {
      padding: $base-spacing-unit;
    }

    @include media-query(desk) {
      padding: double($base-spacing-unit);
    }

    a:not(.btn) {
      color: #756aa4;

      &:hover,
      &:focus {
        text-decoration: none;
        color: #695f94;
      }
    }

    .btn__wrapper {
      display: inline-block;
    }

    .btn {
      background-color: #756aa4;
      border-color: #756aa4;

      &:hover,
      &:focus {
        background-color: #695f94;
        border-color: #695f94;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;

    @include media-query(portable) {
      flex-direction: row-reverse;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      background-color: color(brand, primary);
    }

    @include media-query(desk) {
      padding: $base-spacing-unit double($base-spacing-unit);
    }
  }

  &__body-wrapper {
    flex-grow: 1;
    background-color: color(bw, white);
  }

  &__body {
    max-width: 1162px;
  }
}


.btn__wrapper {
  margin-right: $base-spacing-unit;
}
