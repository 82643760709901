/**
 * component/_listing.scss
 */

/* ==========================================================================
   Listing
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// $example;


/**
 * Listing styles
 */

.listing {
  border: 1px solid color(background, light);

  @include media-query(desk) {
    display: flex;
  }

  &__thumb {
    position: relative;
    min-width: 160px;
    min-height: 100px;
    border-right: 1px solid color(background, light);
    background-color: #f4f4f4;

    @include media-query(desk) {
      min-height: 160px;
    }

    &--link {
      display: block;
      min-width: 124px;
      min-height: 124px;

      &:hover {
        .icon {
          fill: shade(color(brand, primary), 66.666%);
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__content {
    display: flex;
    //align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
    flex: 1;
    padding: 1em;
  }

  &__heading {
    margin: 0;
  }

  &__excerpt {
    max-width: auto;
    margin: 0.5em 0;
  }
}
