/**
 * base/_fieldset.scss
 */

/* ==========================================================================
   Fieldset
   ========================================================================== */

/**
 * Fieldset
 */

fieldset {
  margin: 1em 0;
  padding: 0;
  -webkit-margin-top-collapse: separate;
  border: 0;
}
