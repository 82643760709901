/**
 * components/_nav.scss
 */

/* ==========================================================================
   Navigation
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$initial-item-background-color: #0c1f45;


/**
 * Nav
 */

.nav {
  background-color: color(brand, primary);

  &__list {
    @extend .list--bare;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__item {
    $step: 1;

    @while $step <= 40 {
      &:nth-child(#{$step}) {
        background-color: lighten($initial-item-background-color, ($step * 1%));
      }
      $step: $step + 1;
    }

    &:last-child {
      flex-grow: 1;
    }
  }

  &__link {
    position: relative;
    display: block;
    padding: 1em 2em;
    transition: background-color $base-anim-duration $base-anim-easing;

    &--active {
      position: relative;

      &::before {
        position: absolute;
        top: quarter($base-spacing-unit);
        bottom: quarter($base-spacing-unit);
        left: 0;
        display: block;
        content: '';
        width: quarter($base-spacing-unit);
        background-color: color(brand, secondary);
      }
    }
  }

  a {
    color: color(text, lightest);
    text-decoration: none;
  }

  &__subnav-checkbox {
    &:checked {
      & ~ .nav__link {
        background-color: rgba(255, 255, 255, 0.1);

        .nav__subnav-arrow {
          &::before {
            transform: translate(-2px, 0) rotate(45deg);
          }

          &::after {
            transform: translate(2px, 0) rotate(-45deg);
          }
        }
      }

      & ~ .subnav {
        max-height: 250px;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__subnav-label {
    position: absolute;
    top: 50%;
    right: halve($base-spacing-unit);
    transform: translateY(-50%);
    padding: $base-spacing-unit;

    &:hover {
      cursor: pointer;
    }
  }

  &__subnav-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-2px, -2px);

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: color(bw, white);
      width: 2px;
      height: 7px;
      transition:
        transform $base-anim-duration $base-anim-easing,
        background-color $base-anim-duration $base-anim-easing;
    }

    &::before {
      transform: translate(2px, 0) rotate(45deg);
    }

    &::after {
      transform: translate(-2px, 0) rotate(-45deg);
    }
  }
}


/**
 * Subnav
 */

.subnav {
  max-height: 0;
  overflow: hidden;
  transition:
    max-height $base-anim-duration $base-anim-easing,
    background-color $base-anim-duration $base-anim-easing;

  &__list {
    margin: 0 0 1em 1em;
    list-style: circle;
    color: color(brand, secondary);
  }
}
