/**
 * components/_categories-widget.scss
 */

/* ==========================================================================
   Categories widget
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

//$example


/**
 * Declare local vars (only used here)
 */

.categories-widget {
  display: none;
  overflow: hidden;

  .decorative-heading {
    margin-top: 1em;
  }
}
