/**
 * objects/_listing.scss
 */

/* ==========================================================================
   Listings
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// $example;


/**
 * Listings lists
 */

.listings {
  @extend %list--bare;

  &__item {
    margin-bottom: quarter($base-spacing-unit);
  }
}
