/**
 * components/_button.scss
 */

/* ==========================================================================
   Buttons
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$color--primary: color(bw, white);
$background-color--primary: color(brand, primary);
$background-color--secondary: color(brand, secondary);


/**
 * Base button component styles
 */

.btn {
  display: inline-block;
  padding: halve($base-spacing-unit) $base-spacing-unit;
  border-radius: 0.375em;
  color: $color--primary;
  text-decoration: none;
  border: 0;
  background-color: $background-color--primary;
  transition:
    background-color $base-anim-duration $base-anim-easing,
    border-color $base-anim-duration $base-anim-easing;

  &:hover {
    background-color: shade($background-color--primary, 66.666%);
  }

  .icon {
    fill: currentColor;
  }
}


/**
 * Button modifiers
 */

.btn--secondary {
  background-color: $background-color--secondary;

  &:hover {
    background-color: shade($background-color--secondary, 66.666%);
  }

}

.btn--tertiary {
  color: color(bw, white);
  border: 2px solid transparentize(color(bw, white), 0.85);
  background-color: transparent;
  border-radius: 0;


  &:hover {
    border-color: color(bw, white);
  }
}

.btn--naked {
  background-color: transparent;
  border: none;
}

.btn--right {
  float: right;
}

.btn--has-icon {
  position: relative;

  &--right {
    padding-right: 3.5em;

    .icon {
      width: 1em;
      height: 1em;
      position: absolute;
      top: 50%;
      right: 1.5em;
      transform: translateY(-50%);
    }
  }

  &--left {
    padding-left: 4em;

    .icon {
      width: 1em;
      height: 1em;
      position: absolute;
      top: 50%;
      left: 2em;
      transform: translateY(-50%);
    }
  }
}

.btn--is-square {
  padding: 0;
  width: 60px;
  height: 60px;
  text-indent: -999em;
  white-space: nowrap;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
