/**
 * components/_content-heading.scss
 */

/* ==========================================================================
   Content Heading
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// example;


/**
 * Base button component styles
 */

.content-heading {
  @include font-size(16px);
  margin: 0 0 3em;
  padding-bottom: 0.5em;
  color: color(text, light);
  border-bottom: 1px solid color(text, lightest);
}
