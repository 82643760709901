/**
 * trumps/_text.scss
 */

/* ==========================================================================
   Text
   ========================================================================== */

/**
 * Uppercase text
 */

%text-upper,
.text-upper {
  text-transform: uppercase;
}
