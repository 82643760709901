/**
 * components/_accordion.scss
 */

/* ==========================================================================
   Accordion
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$accordion__toggle--color: color(brand, primary);
$accordion__toggle--active--color: color(brand, secondary);


/**
 * Accordion
 */

.accordion {
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &__item {
    padding: 1em $base-spacing-unit;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  &__title {
    position: relative;
    margin: 0;
    font-weight: normal;

    label {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 24px;
      height: 24px;
      cursor: pointer;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__link {
    text-decoration: none;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateY(-3px);


    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: $accordion__toggle--active--color;
      width: 2px;
      height: 7px;
      transition:
        transform $base-anim-duration $base-anim-easing,
        background-color $base-anim-duration $base-anim-easing;
    }

    &::before {
      transform: translate(-2px, 0) rotate(45deg);
    }

    &::after {
      transform: translate(2px, 0) rotate(-45deg);
    }
  }

  &__content {
    transition:
      transform $base-anim-duration $base-anim-easing,
      opacity $base-anim-duration $base-anim-easing;

    & > *:first-child {
      padding-top: 1em;
    }
  }

  &__toggle-input {
    display: none;

    &:checked {
      ~ .accordion__content {
        max-height: 0;
        opacity: 0;
        transform: translate(0, 50%);
      }

      ~ .accordion__title {
        font-weight: normal;

        i {
          &::before,
          &::after {
            background-color: $accordion__toggle--color;
          }

          &::before {
            transform: translate(2px, 0) rotate(45deg);
          }

          &::after {
            transform: translate(-2px, 0) rotate(-45deg);
          }
        }
      }
    }
  }
}
