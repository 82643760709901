/**
 * components/_island.scss
 */

/* ==========================================================================
   Island
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$island-background-color: color(brand, quaternary);


/**
 * Islands to contain given content
 */

.island {
  margin-bottom: $base-spacing-unit;
  padding: $base-spacing-unit;
  background-color: $island-background-color;

  @include media-query(palm) {
    padding: halve($base-spacing-unit);
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}
