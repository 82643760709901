/**
 * component/_label.scss
 */

/* ==========================================================================
   Label
   ========================================================================== */

/**
 * Label styles
 */

.label {
  display: block;
  margin-bottom: 1em;
  font-weight: 600;

  &__sub-label {
    font-weight: 400;
  }
}
