/**
 * components/_footer.scss
 */

/* ==========================================================================
   footer
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// $example;


/**
 * footer
 */

.footer {
  padding: $base-spacing-unit;
  text-align: center;

  @include media-query(portable) {
    background-color: color(brand, primary);
  }

  @include media-query(desk) {
    padding: double($base-spacing-unit) $base-spacing-unit;
  }

  &__small {
    color: color(text, medium);
    font-size: 100%;

    @include media-query(portable) {
      color: color(bw, white);
    }

    span {
      @include font-size(18px);
      display: block;
      color: color(brand, primary);
      font-family: $font-secondary;
      text-transform: uppercase;

      @include media-query(portable) {
        color: color(bw, white);
      }
    }
  }
}
