/**
 * components/_checkbox.scss
 */

/* ==========================================================================
   Checkbox
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$background-color:            #f4f4f4;
$border-color:                #e9e9e9;
$background-color--secondary: transparentize(color(brand, tertiary), 0.9);
$border-color--secondary:     transparentize(color(brand, tertiary), 0.5);


/**
 * Checkbox
 */

.checkbox {
  position: relative;

  &__input {
    position: absolute;
    left: -999em;
  }

  &__text {
    display: block;
    padding-left: 34px;

    &::before,
    &::after {
      content: "";
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      margin-right: halve($base-spacing-unit);
      width: $base-spacing-unit;
      height: $base-spacing-unit;
    }

    &::after {
      position: absolute;
      top: 13px;
      left: 12px;
      display: block;
      width: 0;
      height: 0;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition:
        opacity $base-anim-duration ease-in-out,
        width $base-anim-duration ease-in-out,
        height $base-anim-duration ease-in-out;

      :checked ~ & {
        width: 12px;
        height: 8px;
        opacity: 1;
      }
    }
  }

  &--primary {
    .checkbox__text {
      &::before {
        background-color: $background-color;
        border: 2px solid $border-color;
      }
      &::after {
        background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%2011.9%208.3%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%4a4a494%3B%7D%0A%3C%2Fstyle%3E%3Cpolygon%20class%3D%22st0%22%20points%3D%224.5%208.3%200%203.9%200.9%203%204.5%206.5%2011%200%2011.9%200.9%20%22%2F%3E%3C%2Fsvg%3E') no-repeat;
      }
    }
  }

  &--secondary {
    .checkbox__text {
      &::before {
        background-color: $background-color--secondary;
        border: 2px solid $border-color--secondary;
      }
      &::after {
        background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%2011.9%208.3%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23756aa4%3B%7D%0A%3C%2Fstyle%3E%3Cpolygon%20class%3D%22st0%22%20points%3D%224.5%208.3%200%203.9%200.9%203%204.5%206.5%2011%200%2011.9%200.9%20%22%2F%3E%3C%2Fsvg%3E') no-repeat;
      }
    }
  }
}
