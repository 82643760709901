/**
 * components/_pagination.scss
 */

/* ==========================================================================
   Pagination
   ========================================================================== */

/**
 * Pagination component
 */

.pagination {
  @extend %list--bare;
  display: flex;

  &__item {
    transition: background-color $base-anim-duration $base-anim-easing;
    line-height: 2em;

    a {
      display: flex;
      align-content: center;
      justify-content: center;
      width: 2em;
      height: 2em;
      text-decoration: none;
    }

    &--current {
      color: color(text, lightest);
      background-color: color(brand, tertiary);
    }

    &--disabled {
      opacity: 0.3;
    }

    &--prev a,
    &--next a {
      width: auto;
    }

    &--prev {
      margin-right: auto;
    }

    &--next {
      margin-left: auto;
    }
  }
}
