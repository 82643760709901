/**
 * components/_logo.scss
 */

/* ==========================================================================
   Logo
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// $example;


/**
 * Logo
 */

.logo {
  display: none;

  @include media-query(desk) {
    display: flex;
    // flex-basis: 130px;
    height: 130px;
    justify-content: center;
    align-items: center;
    padding: $base-spacing-unit;
    background-image: url(../img/logo__background.jpg);
  }
}
