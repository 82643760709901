/**
 * components/_news-listings.scss
 */

/* ==========================================================================
   News listings
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

//$nav-toggle__color: #000;


/**
 * News Listings
 */

.news-listings {
  display: flex;
  flex-wrap: wrap;
  margin: 2em (-$base-spacing-unit);

  &__item {
    padding: 0 $base-spacing-unit;

    @include media-query(lap) {
      flex: 2 0;
      flex-basis: 50%;
      max-width: 50%;
    }

    @include media-query(large-desk) {
      flex: 2 0;
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  &--home {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
}


/**
 * News Listing
 */

.news-listing {
  margin: 1em 0;

  &__figure {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .btn {
      position: absolute;
      bottom: quarter($base-spacing-unit);
      right: quarter($base-spacing-unit);
    }
  }

  &__thumb:hover ~ .btn {
    border-color: color(bw, white);
  }

  &__content {
    color: color(text, medium);
  }

  &__title {
    font-family: $font-primary;
    font-weight: bold;
  }

  &__meta,
  .categories {
    @include font-size(14px);
    display: inline;
    color: color(text, light);
  }

  @include media-query(desk) {
    display: flex;
    align-items: flex-start;

    &__figure {
      flex: 0 0;
      flex-basis: 180px;
      margin-right: $base-spacing-unit;
    }

    &__content {
      flex: 1;
    }

    &__title {
      margin: 0;
    }
  }

  .news-listings--home & {
    @include media-query(lap-and-up) {
      display: flex;
      align-items: flex-start;

      &__figure {
        flex: 0 0;
        flex-basis: 180px;
        margin-right: $base-spacing-unit;
      }

      &__content {
        flex: 1;
      }

      &__title {
        margin: 0;
      }
    }
  }
}
