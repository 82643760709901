/**
 * base/_links.scss
 */

/* ==========================================================================
   Links
   ========================================================================== */

/**
 * Style base links
 */

a {
  color: currentColor;
  text-decoration: underline;
  transition: color $base-anim-duration $base-anim-easing;
}
