/**
 * components/_form.scss
 */

/* ==========================================================================
   Form
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$control__color--success: color(context, success);
$control__color--error: color(context, danger);


/**
 * Form validation styles - powerd by Parsley.js
 */

input.parsley-success,
select.parsley-success,
textarea.parsley-success,
.input.parsley-success,
.select > .parsley-success,
.textarea.parsley-success {
  color: darken($control__color--success, 25%);
  background-color: tint($control__color--success, 40%);
  border: 2px solid $control__color--success;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error,
.input.parsley-error,
.select > .parsley-error,
.textarea.parsley-error {
  color: darken($control__color--error, 25%);
  background-color: tint($control__color--error, 40%);
  border: 2px solid $control__color--error;
}

.parsley-errors-list {
  @include font-size(14px);

  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: $control__color--error;
  list-style-type: none;
  color: darken($control__color--error, 25%);
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s linear $base-anim-duration,
    opacity $base-anim-duration linear;
}

.parsley-errors-list.filled {
  margin-top: 0;
  padding: 0.75em;
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
