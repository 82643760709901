/**
 * base/_headings.scss
 */

/* ==========================================================================
   Headings
   ========================================================================== */

/**
 * Headings
 */

%alpha,
.alpha,
h1,
%beta,
.beta,
h2,
%gamma,
.gamma,
h3,
%delta,
.delta,
h4,
%epsilon,
.epsilon,
h5,
%zeta,
.zeta,
h6 {
  margin: 2em 0 1em;
  font-weight: normal;
}

%alpha,
.alpha,
h1 {
  @include font-size(30px, 1.5);
  color: color(brand, primary);
}

%beta,
.beta,
h2, {
  @include font-size(24px, 1.5);
  color: color(brand, primary);
}

%gamma,
.gamma,
h3 {
  @include font-size(18px, 1.5);
  color: color(text, darkest);
  font-weight: bold;
}

%delta,
.delta,
h4 {
  @include font-size(16px, 1.5);
  color: color(text, darkest);
  font-weight: bold;
}

%epsilon,
.epsilon,
h5 {
  @include font-size(16px, 1.5);
  color: color(text, darkest);
  font-weight: bold;
}

%zeta,
.zeta,
h6 {
  @include font-size(16px, 1.5);
  color: color(text, darkest);
  font-weight: bold;
}


/**
 * Extra large sizes
 */

%mega,
.mega {
  @include font-size(64px);
}
