/**
 * components/_radio.scss
 */

/* ==========================================================================
   Radio
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

$background-color:            #f4f4f4;
$border-color:                #e9e9e9;


/**
 * Radio
 */

.radio {
  display: inline-block;
  margin-right: quarter($base-spacing-unit);
  position: relative;
  line-height: 42px;

  &__input {
    position: absolute;
    left: -999em;
  }

  &__text {

    &::before,
    &::after {
      content: "";
    }

    &::before {
      display: inline-block;
      width: $base-spacing-unit;
      height: $base-spacing-unit;
      vertical-align: middle;
      background-color: $background-color;
      border-radius: 50%;
      border: 2px solid $border-color;
    }

    &::after {
      position: absolute;
      top: 22px;
      left: 12px;
      display: block;
      width: 0;
      height: 0;
      transform: translate(-50%, -50%);
      opacity: 0;
      border-radius: 50%;
      background-color: color(text, medium);
      transition:
        opacity $base-anim-duration ease-in-out,
        width $base-anim-duration ease-in-out,
        height $base-anim-duration ease-in-out;

      :checked ~ & {
        width: halve($base-spacing-unit);
        height: halve($base-spacing-unit);
        opacity: 1;
      }
    }
  }
}
