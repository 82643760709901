/**
 * base/_strong.scss
 */

/* ==========================================================================
   Strong
   ========================================================================== */


/**
 * Strong
 */

strong {
  font-weight: 700;
}
