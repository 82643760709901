/**
 * component/_login.scss
 */

/* ==========================================================================
   Login
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// $example;


/**
 * Login styles
 */

.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__form {
    margin: 0 auto;
    padding: $base-spacing-unit;
    width: 100%;
    max-width: 360px;
    background-color: color(background, light);
  }

  &__heading {
    @include font-size(24px);
    margin: 0;
    font-weight: bold;
    color: color(brand, primary);
  }

  &__hr {
    margin: 2em 0;
    border-color: color(background, lightest);
  }

  a {
    color: color(brand, secondary);

    &:hover {
     text-decoration: none;
    }
  }
}
