/**
 * trumps/_clearfix.scss
 */

/* ==========================================================================
   Clearfix
   ========================================================================== */

/**
 * Clearfixes - @extend %clearfix to prevent class="clearfix" appearing all the time
 */

%clearfix,
.clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
