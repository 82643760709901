/**
 * components/_page-title.scss
 */

/* ==========================================================================
   Page Title
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// $example;


/**
 * Main page title
 */

.page-title {
  @include media-query(portable) {
    color: color(bw, white);
    padding: 0 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
