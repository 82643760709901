/**
 * components/_icon.scss
 */

/* ==========================================================================
   Icons
   ========================================================================== */

.icon {
  fill: currentColor;
  transition: fill $base-anim-duration $base-anim-easing;

  &--power-button {
    width: 18px;
    height: 18px;
    fill: color(bw, white);
  }

  &--cross {
    width: 12px;
    height: 12px;
    fill: #898989;
  }

  &--download {
    width: 16px;
    height: 16px;
    fill: color(bw, white);
  }

  &--download-circle {
    width: 76px;
    height: 76px;
    fill: color(brand, primary);
  }

  &--document-circle {
    width: 76px;
    height: 76px;
    fill: #898989;
  }

  &--arrow--right {
    width: 16px;
    height: 16px;
  }

  &--signature {
    width: 140px;
    height: 44px;
  }
}
