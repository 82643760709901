/**
 * components/_headings.scss
 */

/* ==========================================================================
   Headings
   ========================================================================== */

/**
 * Declare local vars (only used here)
 */

// $example;


/**
 * Headings with inline control
 */

.heading-controls {
  margin: 2em 0 1em;
  overflow: hidden;

  &__heading {
    margin: 0 0 1em;
  }

  @include media-query(lap-and-up) {
    &__heading {
      display: inline-block;
      margin: 0;
      line-height: 60px;
    }

    &__control {
      float: right;
    }
  }
}

